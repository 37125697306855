import React, {Component} from 'react';
import Content from "../../common/containers/Content";
import Col from "../../common/containers/Col";
import Row from "../../common/containers/Row";
import PublicPage from "../../common/containers/PublicPage";
import ClassService from "./ClassService";
import Config from "../../config";
import Ajax from "../../common/ajax";
import Waiting from "../../common/containers/Waiting";
import PrivatePage from "../../common/containers/PrivatePage";
import ConfirmInvoice from "./ConfirmInvoice";
import Panel from "../../common/containers/Panel";

class AdvisoryServicesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            processing: 0,
            invoice: null,
        };
    }

    componentDidMount() {
        this.fetchInvoice();
    }

    fetchInvoice() {
        const invoiceId = this.props.match.params.invoiceId;
        this.setState(state => ({...state, processing: state.processing + 1}));
        const url = `${Config.apiHost}advisory-invoices/${invoiceId}`;
        Ajax.get(url).done(invoice => {
            this.setState(state => ({
                ...state,
                processing: state.processing - 1,
                invoice,
            }));
        });
    }

    render() {
        return (
            <PrivatePage
                hasAside={false}
                title="Assessoria acadêmica"
            >
                <Waiting isProcessing={!!this.state.processing}>
                    <Content>
                        <Row>
                            <Col md={10} mdOffset={1}>
                                {this.state.invoice && (
                                    <Panel>
                                        <ConfirmInvoice invoice={this.state.invoice}/>
                                    </Panel>
                                )}
                            </Col>
                        </Row>
                    </Content>
                </Waiting>
            </PrivatePage>
        );
    }
}

export default AdvisoryServicesPage;