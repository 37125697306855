import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LocaleUtils from "../../common/LocaleUtils";

class ConfirmInvoice extends Component {
    render() {
        const {invoice} = this.props;
        const {service, profile, user} = invoice;
        const serviceTitle = `${service.type_label} - ${service.name} - ${service.formation_label}`;
        return (
            <form method="post" target="pagseguro"
                  action="https://pagseguro.uol.com.br/v2/checkout/payment.html">
                <h3>Confira sua solicitação e confirme</h3>
                <br/>

                <div className="well">
                    <h4>
                        <b>Serviço contratado:</b>
                        <br/>
                        {serviceTitle}
                        <br/>
                        <i>
                            {service.description}
                        </i>
                    </h4>
                    <h4><b>Valor:</b> R$ {LocaleUtils.currency(invoice.total)}</h4>
                    <h4>Dados do comprador:</h4>
                    <h4><b>Nome:</b> {profile.fullname}</h4>
                    <h4><b>Telefone:</b> {profile.phone_number}</h4>
                    <h4><b>E-mail:</b> {user.email}</h4>
                </div>

                <h4 className="well">
                    Ao clicar em pagar, você será direcionado para o site do PagSeguro para efetuar o pagamento.
                    {' '}
                    Após a confirmação do pagamento, um de nossos coordenadores entrará em contato com você.
                </h4>

                 {/*Campos obrigatórios -->*/}
                <input name="receiverEmail" type="hidden" value="contact@fastformat.co"/>
                <input name="currency" type="hidden" value="BRL"/>

                {/*-- Itens do pagamento (ao menos um item é obrigatório) -->*/}
                <input name="itemId1" type="hidden" value={service.id}/>
                <input name="itemDescription1" type="hidden" value={serviceTitle}/>
                <input name="itemAmount1" type="hidden" value={invoice.total}/>
                <input name="itemQuantity1" type="hidden" value="1"/>
                <input name="itemWeight1" type="hidden" value="0"/>

                {/*-- Código de referência do pagamento no seu sistema (opcional) -->*/}
                <input name="reference" type="hidden" value={"ADVISORY-" + invoice.id}/>

                {/*-- Informações de frete (opcionais) -->*/}
                {/*<input name="shippingType" type="hidden" value="1"/>*/}
                {/*<input name="shippingAddressPostalCode" type="hidden"*/}
                       {/*value="01452002"/>*/}
                {/*<input name="shippingAddressStreet" type="hidden"*/}
                       {/*value="Av. Brig. Faria Lima"/>*/}
                {/*<input name="shippingAddressNumber" type="hidden" value="1384"/>*/}
                {/*<input name="shippingAddressComplement" type="hidden"*/}
                       {/*value="5o andar"/>*/}
                {/*<input name="shippingAddressDistrict" type="hidden"*/}
                       {/*value="Jardim Paulistano"/>*/}
                {/*<input name="shippingAddressCity" type="hidden"*/}
                       {/*value="Sao Paulo"/>*/}
                {/*<input name="shippingAddressState" type="hidden" value="SP"/>*/}
                {/*<input name="shippingAddressCountry" type="hidden" value="BRA"/>*/}

                {/*-- Dados do comprador (opcionais) -->*/}
                <input name="senderName" type="hidden" value={profile.fullname}/>
                <input name="senderAreaCode" type="hidden" value=""/>
                <input name="senderPhone" type="hidden" value=""/>
                <input name="senderEmail" type="hidden" value={user.email}/>

                <input name="encoding" type="hidden" value="UTF-8"/>

                {/*-- submit do form (obrigatório) -->*/}
                <input alt="Pague com PagSeguro" name="submit" type="image"
                       src="https://stc.pagseguro.uol.com.br/public/img/botoes/pagamentos/209x48-pagar-assina.gif"/>
            </form>
        );
    }
}

ConfirmInvoice.propTypes = {
    invoice: PropTypes.object.isRequired,
};

export default ConfirmInvoice;
